import * as Sentry from '@sentry/react';
import { getItem } from '@utils/localStorage';
import axios from 'axios';
import {
  AddCarrierArgs,
  BedrockOnboardArgs,
  DataSourceOptions,
  FrontConversation,
  GetBookArgs,
  GetCredentialsArgs,
  InitializeOpenBookUserArgs,
  PostEnrollArgs,
  PrescriptionDetailResults,
  PrescriptionResults,
  ProviderAddressUpdate,
  ProviderSearchArgs,
  PutCredentialsArgs,
  RefreshCarrierArgs,
  ScheduledEmails,
  SendQuotesArgs,
  SendSoaArgs,
  SilenceAEPArgs,
  UpdateProviderArgs,
  UpsertPrescriptionArgs,
} from './types';

// Creates headers for each request.
// Usertoken: [USER TOKEN] (sourced from user in local storage).
const getHeaders = () => {
  if (typeof window === 'undefined') return {};

  try {
    const userInStorage = getItem('user');

    if (userInStorage) {
      return { usertoken: userInStorage.token };
    }

    return {};
  } catch (e) {
    Sentry.captureException(e);
    return {};
  }
};

const api = axios.create({
  baseURL: process.env.GRAPHQL_SERVER_ENDPOINT,
});

// Add authorization headers (Usertoken) to outgoing requests.
api.interceptors.request.use((config) => ({
  ...config,
  headers: { ...config.headers, ...getHeaders() },
}));

// Capture Sentry errors for all incoming request errors.
api.interceptors.response.use(
  (response) => response,
  (error) => {
    Sentry.captureException(error);
    return Promise.reject(error);
  }
);

export const getUserInfo = async ({ userId }) => {
  const { data } = await api.post('/auth/get-user-info', {
    userId,
  });
  return data;
};

export const switchUser = async (id: string) => {
  try {
    const { data } = await api.post('/auth/switch-user', { id });
    return data;
  } catch (err) {
    Sentry.captureException(err);
    return { data: { status: 'ERROR' } };
  }
};

export const getCountyOptions = async ({ zipCode }) => {
  const { data } = await api.get('/crm/get-address-options', {
    params: {
      zipCode,
    },
  });
  return data || [];
};

export const searchPrescriptions = async ({ searchString }) => {
  const { data }: PrescriptionResults = await api.get('/crm/search-prescriptions', {
    params: {
      searchString,
    },
  });
  return data?.connecturePrescriptions || [];
};

export const deletePrescription = async ({ prescriptionId }) => {
  await api.post('/crm/delete-prescription', { id: prescriptionId });
};

export const getPrescriptionDetails = async ({ drugId }) => {
  const { data }: PrescriptionDetailResults = await api.get('/crm/get-prescription-details', {
    params: {
      drugId,
    },
  });
  return data;
};

export const searchPharmacies = async ({ searchString, zipCode, radius }) => {
  const { data } = await api.get('/crm/search-pharmacies', {
    params: {
      radius,
      searchString,
      zipCode,
    },
  });
  return data?.connecturePharmacies || [];
};

export const createPharmacy = async ({ agentId, pharmacy, userId }) => {
  await api.post('/crm/create-pharmacy', {
    agentId,
    pharmacyDetails: pharmacy,
    userId,
  });
  return true;
};

export const deletePharmacy = async ({ pharmacyId }) => {
  await api.post('/crm/delete-pharmacy', { id: pharmacyId });
};

export const updateAddress = async ({ address, userId }) => {
  const { data } = await api.post('/crm/update-address', { ...address, userId });
  return data;
};

export const updateAgencyAddress = async ({ agencyId, address }) => {
  const { data } = await api.post('/crm/update-agency-address', { agencyId, address });
  return data;
};

export const searchProviders = async ({ searchString, zipCode, radius, type, npi }: ProviderSearchArgs) => {
  const { data } = await api.get('/crm/search-providers', {
    params: {
      searchString,
      type,
      radius,
      zipCode,
      npi,
    },
  });

  // @TODO - this is a temporary fix to group providers by NPI
  // This should be fixed in the backend
  const providers = [];
  data.forEach((provider) => {
    const existingProvider = providers.find((p) => p.npi === provider.npi);
    if (existingProvider) {
      // If the provider already exists, just push the new address to the addresses array
      existingProvider.addresses.push(provider.address);
    } else {
      // If the provider does not exist, add it to the array and start a new array for the addresses
      providers.push({ ...provider, addresses: [provider.address] });
    }
  });

  return providers || [];
};

export const createProvider = async ({ agentId, provider, userId }) => {
  await api.post('/crm/create-provider', {
    agentId,
    providerDetails: provider,
    userId,
  });
  return true;
};

export const updateProviderRole = async ({ providerId, role }: UpdateProviderArgs): Promise<void> => {
  await api.post('/crm/update-providers', {
    updates: [{ id: providerId, provider_role: role }],
  });
};

export const updateProviderAddress = async ({
  provider,
  userId,
}: {
  provider: ProviderAddressUpdate;
  userId: string;
}): Promise<void> => {
  await api.post('/crm/update-provider-address', {
    provider,
    userId,
  });
};

export const deleteProvider = async ({ providerId }) => {
  await api.post('/crm/delete-provider', { id: providerId });
  return true;
};

export const upsertPrescription = async ({ agentId, prescription, userId }) => {
  const dosageData = prescription.dosages.find((el) => el.referenceNDC === prescription.referenceNdc);

  const formattedPrescription: UpsertPrescriptionArgs = {
    agentId,
    baseName: prescription.name,
    dosageData,
    drugID: prescription.drugID,
    frequency: prescription.frequency,
    generic: prescription.drugTypeID === 2,
    id: prescription.id,
    labelName: dosageData.labelName,
    quantity: +prescription.times,
    laproActive: prescription.laproActive,
    userId,
  };
  await api.post('/crm/upsert-prescription', {
    ...formattedPrescription,
  });
};

export const togglePrescriptionInQuote = async ({ id, laproActive }) => {
  await api.post('/crm/toggle-prescription-recommendations', {
    prescriptions: [{ id, isDrugConsidered: laproActive }],
  });
  return true;
};

export const togglePharmacyInQuote = async ({ id, laproActive }) => {
  await api.post('/crm/toggle-pharmacy-recommendations', { pharmacies: [{ id, isPharmacyConsidered: laproActive }] });
  return true;
};

export const toggleProviderInQuote = async ({ id, laproActive }) => {
  await api.post('/crm/toggle-provider-recommendations', { providers: [{ id, isProviderConsidered: laproActive }] });
  return true;
};

export const getUserDetails = async ({ userId }) => {
  const { data } = await api.get(`/crm/user-details?userId=${userId}`);
  return data;
};

export const createLead = async ({ userInfo }) => {
  const { data } = await api.post('/crm/create-lead', {
    userInfo,
  });
  return data;
};

export const deleteLead = async ({ agentId, userId }) => {
  const { data } = await api.post('/crm/delete-lead', {
    agentId,
    userId,
  });
  return data;
};

interface updateUserDetailsArgs {
  userId: string;
  userInfo: any;
  userQualification?: any;
}

export const updateUserDetails = async ({ userId, userInfo, userQualification }: updateUserDetailsArgs) => {
  await api.post('/crm/update-lead', { userId, userInfo, userQualification });
};

export const getUserHistory = async ({ userId, filters }) => {
  const { data } = await api.get(`/crm/get-user-history?userId=${userId}&filters=${filters}`);
  return data;
};

export const getApplicationData = async () => {
  const { data } = await api.get('/crm/application-data');
  return data;
};

export const getSOALink = async (soaId: string) => {
  const { data } = await api.get(`/crm/soa-link?id=${soaId}`);
  return data.link;
};

export const getApplicationPdf = async ({ planId }) => {
  const { data } = await api.get(`/crm/get-application-pdf?planId=${planId}`, {
    responseType: 'arraybuffer',
  });
  return data;
};

export const getUserRelationships = async ({ userId }) => {
  const { data } = await api.get(`/crm/get-relationships?userId=${userId}`);
  return data;
};

export const addUserRelationship = async ({ fromUserId, toUserId, type }) => {
  const response = await api.post('/crm/add-relationship', {
    fromUserId,
    toUserId,
    type,
  });
  return response.data;
};

export const deleteUserRelationship = async ({ relationshipId }) => {
  const response = await api.delete(`/crm/delete-relationship?relationshipId=${relationshipId}`);
  return response;
};

export const getMsQuotes = async (args): Promise<any> => {
  const params = new URLSearchParams(args);
  const endpoint = `${args.dataSource === DataSourceOptions.LAPro ? 'get' : 'csg'}-medsup-quotes`;
  return api.get(`crm/${endpoint}?${params.toString()}`);
};

export const getMapdQuotes = async ({ userId, agentId }): Promise<any> => {
  const params = new URLSearchParams({
    userId,
    agentId,
  });

  return api.get(`/crm/get-mapd-quotes?${params.toString()}`);
};

export const getPdpQuotes = async ({ userId, agentId }): Promise<any> => {
  const params = new URLSearchParams({
    userId,
    agentId,
  });

  return api.get(`/crm/get-pdp-quotes?${params.toString()}`);
};

export const getMaQuotes = async ({ userId, agentId }): Promise<any> => {
  const params = new URLSearchParams({
    userId,
    agentId,
  });

  return api.get(`/crm/get-ma-quotes?${params.toString()}`);
};

export const getPdpPlanDetails = async ({ sessionId, connecturePlanId }): Promise<any> => {
  const params = new URLSearchParams({
    sessionId,
    connecturePlanId,
  });

  return api.get(`/crm/get-plan-details?${params.toString()}`);
};

export const sendQuotes = async (body: SendQuotesArgs): Promise<any> => api.post('/crm/send-quotes', body);

export const sendFirstMeetingPortalInvite = async ({ userId, agentId }) =>
  api.post('/crm/send-first-meeting-email', { agentId, userId });

export const getPbpPlan = async ({ cmsId, contractPeriod }): Promise<any> => {
  const params = new URLSearchParams({
    cmsId,
    contractPeriod,
  });
  return api.get(`/crm/get-pbp-plan?${params.toString()}`);
};

export const createSoa = async (body: { userId: string }): Promise<any> => api.post('/crm/create-soa', body);

export const postSoa = async (body: SendSoaArgs): Promise<any> => api.post('/crm/soa', body);

export const postEnroll = async (body: PostEnrollArgs): Promise<any> => api.post('/crm/plan-enroll', body);

export const autocompleteAddress = async ({ search }): Promise<any> => {
  const params = new URLSearchParams({
    search,
  });
  return api.get(`/crm/address-autocomplete?${params.toString()}`, {
    headers: getHeaders(),
  });
};

export const submitSale = async (body): Promise<any> => api.post('/crm/submit-sale', body);

export const getPlaceDetails = async ({ placeId }): Promise<any> => {
  const params = new URLSearchParams({
    placeId,
  });
  return api.get(`/crm/get-place-details?${params.toString()}`);
};

export const getAddressOptions = async ({ zipCode }): Promise<any> => {
  const params = new URLSearchParams({
    zipCode,
  });

  return api.get(`/crm/get-address-options?${params.toString()}`);
};

export const searchPreferredPharmacies = async ({ zipCode, planId, radius, planPharmacyType }): Promise<any> => {
  const params = new URLSearchParams({
    planId,
    zipCode,
    radius,
    planPharmacyType,
  });

  return api.get(`/crm/search-preferred-pharmacies?${params.toString()}`);
};

export const getAgentDrxLink = async (body): Promise<any> => api.post('/crm/agent-drx-quotes-link', body);

export const getAgentHome = async ({ timespan, isDemo }): Promise<any> => {
  const { data } = await api.get(`/crm/agent-home?timespan=${timespan}&isDemo=${isDemo}`);
  return data;
};

export const getScheduledEmails = async ({ agentId, userId }): Promise<ScheduledEmails[]> => {
  const { data } = await api.get('/crm/get-scheduled-emails', {
    params: { agentId, userId },
  });
  return data;
};

export const getFrontConversations = async ({ agentId, userId }): Promise<FrontConversation[]> => {
  const { data } = await api.get('/crm/get-front-conversations', {
    params: { agentId, userId },
  });
  return data;
};

export const silenceAEP = async (body: SilenceAEPArgs): Promise<any> => {
  return api.post('/crm/silence-aep', body);
};

// Create New Openbook User
export const initializeOpenbookUser = async (body: InitializeOpenBookUserArgs): Promise<any> => {
  return api.post('/openbook/initialize-user', body);
};

// Get Openbook Metrics Data
export const getBookMetrics = async (params): Promise<any> => {
  const { data } = await api.get('/openbook/metrics', {
    params,
  });
  return data;
};

// Get Openbook Credentials Data
export const getBookCredentials = async (params): Promise<any> => {
  const { data } = await api.get('/openbook/book-credentials', {
    params,
  });
  return data;
};

// Get Openbook Table Data
export const getBook = async (params: GetBookArgs): Promise<any> => {
  const { data } = await api.get('/openbook/book', {
    params,
  });
  return data;
};

// Start Carrier Refresh
export const postRefreshCarrier = async (body: RefreshCarrierArgs): Promise<any> => {
  return api.post('/openbook/refresh-carrier', body);
};

// Add Carrier
export const postAddCarrier = async (body: AddCarrierArgs): Promise<any> => {
  const { data } = await api.post('/openbook/add-carrier', body);
  return data;
};

// Get Openbook Credential for a Carrier
export const getCredentialsRecord = async (params: GetCredentialsArgs): Promise<any> => {
  const { data } = await api.get('/openbook/credentials', {
    params,
  });
  return data;
};

// Update Credential Auth Token
export const putCredentials = async (body: PutCredentialsArgs): Promise<any> => {
  return api.put('/openbook/credentials', body);
};

export const agencySignUp = async (body: BedrockOnboardArgs): Promise<any> => {
  return api.post('/onboarding/sign-up', body);
};

export const getSummaryOfBenefits = async ({ effectiveDate, hNumber, planId, segmentId }): Promise<string> => {
  const { data } = await api.get('/crm/get-summary-of-benefits', {
    params: { effectiveDate, hNumber, planId, segmentId },
  });
  return data;
};

export const duplicateLeadCheck = async ({ email, phoneNumber }): Promise<any> => {
  return api.post('/crm/check-duplicate-lead', { email, phoneNumber });
};

export const submitSurvey = async ({ response, userComment, userId }): Promise<any> => {
  return api.post('/surveys/submit', { response, userComment, userId });
};

export const getExistingSurvey = async ({ userId }): Promise<any> => {
  const { data } = await api.get('/surveys/get-survey', {
    params: { userId },
  });
  return data;
};
