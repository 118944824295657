import { encodeSlug } from '@utils/slugs';
import { agentsWithStates } from './fsmTeam';
import { UserRoles } from './index';
import { stateCodeToName } from './stateData/stateCodeToName';

export enum Routes {
  about = '/about',
  aca = '/aca',
  account = '/account', // this is used to match all /account/* routes.
  accountCallUs = '/account/call-us',
  accountSoa = '/account/soa',
  accountSurveyUpdatePharmacies = '/account/survey/update-pharmacies',
  accountSurveyUpdatePrescriptions = '/account/survey/update-prescriptions',
  accountSurveyUpdateProviders = '/account/survey/update-providers',
  accountSurveyUpdateSoa = '/account/survey/scope-of-appointment',
  accountSurveyUpdateZipcode = '/account/survey/update-zipcode',
  accountVirgil = '/account/virgil',
  aepIntro = '/account/aep/intro',
  aepOptOut = '/account/aep/opt-out',
  aepPharmacies = '/account/aep/pharmacies',
  aepPlans = '/account/aep/plans',
  aepPrescriptions = '/account/aep/prescriptions',
  aepProviders = '/account/aep/providers',
  aepRecommendations = '/account/aep/recommendations',
  aepZipCode = '/account/aep/zip-code',
  agent = '/agent',
  agentRevenueCalculator = '/calculators/agent-revenue',
  agents = '/agents',
  agentsOpenbook = '/agents/openbook',
  agentsPipeline = '/agents/pipeline',
  aircall = '/agents/aircall',
  articles = '/articles',
  bedrockIrmaaSurcharges = '/bedrock-irmaa-surcharges.png',
  bestMedicareSupplement = '/best-medicare-supplement',
  bugReport = 'https://linear.app/fairsquaremedicare/new?template=bda3afd2-829f-4c6a-8f3c-e853ed1f5041',
  callUs = '/call-us',
  checkEmail = '/check-email',
  chooseAccount = '/account/choose-account',
  chooseYourAgent = '/choose-your-agent',
  cmsComplaint = '/products/cms-complaint',
  dashboard = '/account/dashboard',
  demo = '/demo',
  drxLink = '/drx-link',
  featureRequest = 'https://linear.app/fairsquaremedicare/new?template=3ef808af-8eff-4931-be99-2d997306535a',
  getStarted = '/get-started',
  guideIntroduction = '/guide/introduction',
  helloVirgil = '/hello-virgil',
  index = '/',
  invite = '/invite',
  lead = '/agents/lead',
  linearOverview = 'https://linear.app/fairsquaremedicare/view/88a2b52c-d77a-42b2-afb4-a75a680def3f',
  login = '/login',
  medicareAdvantage = '/medicare-advantage',
  medicareAgent = '/medicare-agent',
  medicareAgents = '/medicare-agents',
  medicareBroker = '/medicare-broker',
  medicareBrokers = '/medicare-brokers',
  medicareCardProtector = '/medicare-card-protector',
  medicareSupplement = '/medicare-supplement',
  medicarewala = '/medicarewala',
  newsletter = '/newsletter',
  newToMedicare = '/new-to-medicare',
  privacyPolicy = '/privacy-policy',
  quotes = '/account/quotes',
  resourcesHowToApply = '/resources/how-to-apply-a-b',
  socialSecurityCalculator = '/calculators/social-security-retirement-benefits',
  stopSpam = '/products/stop-spam',
  submitCerts = 'https://docs.google.com/forms/d/e/1FAIpQLSdkhBH37nDFHQq7Qlby47_LxXssWG5Flt2L5stqwjtgRnYR5w/viewform?usp=sf_link',
  termsOfService = '/terms-of-service',
  topRatedMedicareAdvantage = '/top-rated-medicare-advantage',
  verify = '/verify',
  virgil = '/virgil',
  survey = '/survey',
  obscuredCalendlyConfirmation = '/obscured-calendly-confirmation',
}

export const RoutesByUserRoles = {
  [UserRoles.USER]: [Routes.account, Routes.drxLink],
  [UserRoles.AGENT]: [
    Routes.agents,
    Routes.agentsPipeline,
    Routes.agentsOpenbook,
    Routes.aircall,
    Routes.lead,
    Routes.drxLink,
  ],
  [UserRoles.SDR]: [Routes.agents, Routes.agentsPipeline, Routes.aircall, Routes.lead, Routes.drxLink],
  [UserRoles.INTERNAL]: [
    Routes.account,
    Routes.agents,
    Routes.agentsPipeline,
    Routes.agentsOpenbook,
    Routes.aircall,
    Routes.lead,
    Routes.drxLink,
  ],
};

export const AepRoutes = [
  Routes.aepIntro,
  Routes.aepOptOut,
  Routes.aepPharmacies,
  Routes.aepPlans,
  Routes.aepPrescriptions,
  Routes.aepProviders,
  Routes.aepRecommendations,
  Routes.aepZipCode,
];

export const AgentRoutes = RoutesByUserRoles[UserRoles.AGENT];

// Routes that users cannot access
export const nonUserRoutes = [Routes.agents, Routes.agentsPipeline, Routes.agentsOpenbook, Routes.aircall, Routes.lead];

// Authenticated routes are all routes that require a user to be logged in.
// These are the unique values from RoutesByUserRoles.
export const AuthenticatedRoutes = Array.from(
  new Set(Object.values(RoutesByUserRoles).reduce((acc, val) => [...acc, ...val], []))
);

export const BedrockRoutes = [
  // General
  Routes.agentRevenueCalculator,
  Routes.drxLink,
  // Auth
  Routes.checkEmail,
  Routes.index,
  Routes.verify,
  // Agents
  Routes.agents,
  Routes.agentsPipeline,
  Routes.agentsOpenbook,
  Routes.aircall,
  Routes.lead,
  // Clients
  Routes.accountSoa,
  Routes.accountSurveyUpdatePharmacies,
  Routes.accountSurveyUpdatePrescriptions,
  Routes.accountSurveyUpdateProviders,
  Routes.accountSurveyUpdateZipcode,
  Routes.accountVirgil,
  Routes.aepIntro,
  Routes.aepOptOut,
  Routes.aepPharmacies,
  Routes.aepPlans,
  Routes.aepProviders,
  Routes.aepRecommendations,
  Routes.aepZipCode,
  Routes.bedrockIrmaaSurcharges,
  Routes.dashboard,
  Routes.drxLink,
  Routes.getStarted,
  Routes.quotes,
  Routes.resourcesHowToApply,
  Routes.virgil,
];

export const agentStateRoutes = agentsWithStates
  .map((agent) =>
    agent.states.map(
      (stateCode) => `${Routes.medicareAgent}/${encodeSlug(stateCodeToName[stateCode])}/${encodeSlug(agent.name)}`
    )
  )
  .flat();

export const agentBrokerRoutes = agentsWithStates
  .map((agent) =>
    agent.states.map(
      (stateCode) => `${Routes.medicareBroker}/${encodeSlug(stateCodeToName[stateCode])}/${encodeSlug(agent.name)}`
    )
  )
  .flat();

export const agentRoutes = agentsWithStates.map((agent) => `${Routes.agent}/${encodeSlug(agent.name)}`).flat();

export const googlePhonePaths = [
  Routes.medicareAdvantage,
  Routes.medicareBrokers,
  Routes.medicareCardProtector,
  Routes.medicareSupplement,
  Routes.newToMedicare,
  ...agentRoutes,
  ...agentBrokerRoutes,
  ...agentStateRoutes,
] as string[];

export const ApiRoutes = {
  blogMapd: `${process.env.GRAPHQL_SERVER_ENDPOINT}/blog/mapd`,
  blogMedigap: `${process.env.GRAPHQL_SERVER_ENDPOINT}/blog/medigap`,
} as const;
