import { StateCode } from '@constants/stateData/StateCode';
import cb from '@public/enhanced/cb.png';
import laura from '@public/enhanced/laura.png';
import laurie from '@public/enhanced/laurie.png';
import rachel from '@public/enhanced/rachel.png';
import scott from '@public/enhanced/scott.png';
import steve from '@public/enhanced/steve.png';
import theresa from '@public/enhanced/theresa.png';
import thomas from '@public/enhanced/thomas.jpg';
import caroline from '@public/headshots/caroline.jpeg';
import chrisHudak from '@public/headshots/chris-hudak.jpeg';
import dan from '@public/headshots/dan.jpeg';
import gina from '@public/headshots/gina.jpeg';
import jaspreet from '@public/headshots/jaspreet.png';
import jessica from '@public/headshots/jessica.jpeg';
import joe from '@public/headshots/joe.jpeg';
import kendra from '@public/headshots/kendra.jpeg';
import leif from '@public/headshots/leif.jpeg';
import max from '@public/headshots/max.jpeg';
import mindy from '@public/headshots/mindy.jpeg';
import nate from '@public/headshots/nate.jpeg';
import pete from '@public/headshots/pete.jpeg';
import peterTamayo from '@public/headshots/peter.jpeg';
import sam from '@public/headshots/sam.jpeg';
import chris2 from '@public/headshots/secondary/chris.jpg';
import joe2 from '@public/headshots/secondary/joe.jpg';
import laurie2 from '@public/headshots/secondary/laurie.jpg';
import leif2 from '@public/headshots/secondary/leif.jpg';
import mindy2 from '@public/headshots/secondary/mindy.jpg';
import pete2 from '@public/headshots/secondary/pete.jpg';
import peter2 from '@public/headshots/secondary/peter.jpg';
import scott2 from '@public/headshots/secondary/scott.png';
import stephen2 from '@public/headshots/secondary/stephen.jpg';
import steve2 from '@public/headshots/secondary/steve.jpg';
import theresa2 from '@public/headshots/secondary/theresa.jpg';
import thomas2 from '@public/headshots/secondary/thomas.jpg';
import waine2 from '@public/headshots/secondary/waine.jpg';
import waine from '@public/headshots/waine-bresky.jpeg';
import zo from '@public/headshots/zo.jpeg';
import { StaticImageData } from 'next/image';

export type HeadshotData = {
  index?: number;
  bio?: string;
  calendly?: string;
  showInChooseYourAgent?: boolean;
  image: StaticImageData;
  image2?: StaticImageData;
  name: string;
  pronoun?: string;
  role: Roles;
  states?: StateCode[];
  videoUrl?: string;
};

enum Pronouns {
  he = 'He',
  she = 'She',
}

export enum Roles {
  bizOps = 'BizOps',
  brokerageTeamLead = 'Brokerage Team Lead',
  contentLead = 'Content Lead',
  customerSuccessLead = 'Customer Success Lead',
  founder = 'Founder, CEO',
  headOfBrokerage = 'Head of Brokerage',
  headOfGrowth = 'Head of Growth',
  headOfPartnerships = 'Head of Partnerships',
  medicareAdvisor = 'Medicare Advisor',
  operations = 'Operations',
  operationsSupport = 'Operations Support',
  receptionist = 'Receptionist',
  softwareEngineer = 'Software Engineer',
}

export const isAgent = (person: HeadshotData) => [Roles.medicareAdvisor, Roles.brokerageTeamLead].includes(person.role);

export const fsmTeam: HeadshotData[] = [
  {
    name: 'Dan Petkevich',
    role: Roles.founder,
    image: dan,
  },
  {
    name: 'Peter Austin',
    role: Roles.brokerageTeamLead,
    image: pete,
    image2: pete2,
    pronoun: Pronouns.he,
    states: [StateCode.KS, StateCode.MT],
    bio:
      'Our agent Pete knows that going into Medicare is not a seamless transition, but with the right education and directional advice it can be an easy transition. After 20 years in client services in multiple industries, he was motivated to bring that same level of care and devotion to helping seniors find the best healthcare plan. In his freetime, Pete is an adrenaline junkie who loves being outside, and spending time with his wife and dogs. Originally from Big Sky country, Pete now lives in Eastern Kansas.',
    calendly: 'peter-1207',
  },
  {
    name: 'Scott Cole',
    role: Roles.brokerageTeamLead,
    image: scott,
    image2: scott2,
    pronoun: Pronouns.he,
    states: [StateCode.SC, StateCode.NJ],
    calendly: 'scott-fsm',
    bio:
      'For Scott, the source of pride working in Medicare is each one of his clients. He is an expert and juggling the timelines, expectations, and all the perspectives that come with finding the perfect Medicare plan for everyone. Scott is a resident of Charleston, South Carolina by way of Northern New Jersey. He enjoys spending his free time attending live sporting, concert and theater events.',
  },
  {
    name: 'Laurie Bortolotti',
    role: Roles.medicareAdvisor,
    image: laurie,
    image2: laurie2,
    showInChooseYourAgent: true,
    pronoun: Pronouns.she,
    states: [StateCode.CA],
    videoUrl: 'https://fsm-agent-videos.s3.amazonaws.com/laurie-compressed.mp4',
    bio:
      'Laurie takes pride in making the complex process of finding the best Medicare plan simple and straightforward, and building trusting relationships with her clients. After joining Fair Square in 2022, she found that our approach was honest and refreshing compared to other Medicare brokerages after a career in the industry. Born in Canada, Laurie is now a Californian who enjoys beachfront walks and volunteering through her church.',
    calendly: 'laurie-216',
  },
  {
    name: 'Christopher Bryant',
    role: Roles.medicareAdvisor,
    image: cb,
    image2: chris2,
    pronoun: Pronouns.he,
    states: [StateCode.AK, StateCode.IN],
    bio:
      'Chris is one of the first agents hired by our founder. Fair Square’s technology allowed him to more easily compare plans and share his knowledge with clients. Chris says the sales tactics at other agencies motivated him to join our team where we put you first. He takes great pride in recommending the best plan for every senior he speaks with. Originally from Alaska, Chris now resides in Indiana and likes to spend his free time hiking with his wife and spending time on his motorcycle.',
    calendly: 'cbfairsquaremedicare',
  },
  {
    name: 'Joseph Casamassima',
    role: Roles.medicareAdvisor,
    image: joe,
    image2: joe2,
    pronoun: Pronouns.he,
    states: [StateCode.TX],
    bio:
      'Joe has worked for other Medicare brokerages in the past, but he says nothing comes close to the way we treat our clients and how much he has learned since joining the team in 2021. He came into this industry because healthcare is not not a decision to be taken lightly, and he takes pride in helping people find their best fit plan. Outside of work, you can find Joe in Fort Worth, TX. He loves sports, music, and spending time with his family.',
    calendly: 'joe-1021',
  },
  {
    name: 'Thomas Kunst',
    role: Roles.medicareAdvisor,
    image: thomas,
    image2: thomas2,
    pronoun: Pronouns.he,
    states: [StateCode.MI],
    calendly: 'thomas-fsm',
    videoUrl: 'https://fsm-agent-videos.s3.amazonaws.com/thomas-compressed.mp4',
    bio:
      'Thomas joined us in the summer of 2022 from a large insurance brokerage where he felt uncomfortable with the predatory nature of their tactics. Now on the Fair Square team, he takes pride in being a trustworthy source rather than just selling people Medicare plans even when it’s not right for them. A resident of Northern Michigan, Thomas enjoys the lakes, woods, and golf courses. During his free time, he likes to spend time with his family and two poodles, and ride his bike (weather permitting).',
  },
  {
    name: 'Steve Bowman',
    role: Roles.medicareAdvisor,
    image: steve,
    image2: steve2,
    pronoun: Pronouns.he,
    states: [StateCode.IN],
    calendly: 'sjjtbow',
    bio:
      'Steve joined Fair Square Medicare in July 2022. After an unsavory experience at a Medicare call center, he felt his calling was to do right by his clients instead. He takes pride in his ability to clarify a confusing situation, and later hearing client success stories. A resident of Bloomington, Indiana, Steve enjoys remodeling homes, cycling, and spending time outside of work with his family.',
  },
  {
    name: 'Theresa Hyder',
    role: Roles.medicareAdvisor,
    image: theresa,
    image2: theresa2,
    pronoun: Pronouns.she,
    states: [StateCode.IN],
    calendly: 'theresa-166',
    bio:
      'Theresa has been with Fair Square since March 2022, and compared to other agencies she’s worked for, the informative and helpful culture was a breath of fresh air.  As a veteran, she especially enjoys setting up other vets with extra benefits they might not otherwise get at no cost. Educating Medicare beneficiaries to all their extra benefits from years of hard work brings her joy. Outside of work, Theresa enjoys vintage shopping, reading, and watching thrillers. She has lived in Indianapolis, the Crossroads of America for 20 years.',
  },
  {
    name: 'Waine Bresky',
    role: Roles.medicareAdvisor,
    image: waine,
    image2: waine2,
    pronoun: Pronouns.he,
    calendly: 'waine-3',
    states: [StateCode.SC],
    bio:
      'After over 20 years as a sales leader for companies such as Market Tech Media, LivingSocial and Indoor Media, Waine found himself looking for a change from managing distributed salesforces. As there is an imperative need to help seniors navigate through the complicated landscape of Medicare plans and benefits, Waine is devoted to helping others. Outside the office, you might catch Waine cruising the East Coast on a Harley Davidson. A recent transplant from the Northeast, he now resides in the beautiful low country of South Carolina.',
  },
  {
    name: 'Stephen Lee',
    role: Roles.medicareAdvisor,
    image: stephen2,
    image2: stephen2,
    showInChooseYourAgent: true,
    pronoun: Pronouns.he,
    states: [StateCode.MA],
    calendly: 'stephen-fsm',
    videoUrl: 'https://fsm-agent-videos.s3.amazonaws.com/stephen-compressed.mp4',
    bio:
      'Stephen joined Fair Square in late 2022 after working for many years in financial services. Since joining our team, helping our clients has left him with a great deal of pride and satisfaction. Stephen lives in Randolph, MA, so he is well aware of the unique rules of Medicare in this state. He and his husband Jack have two cats. A passion of his outside of work is the stage, where he has been acting in plays and musicals since he was 6 years old.',
  },
  {
    name: 'Jessica Ramsay',
    role: Roles.operationsSupport,
    image: jessica,
  },
  {
    name: 'Jaspreet Kaur',
    role: Roles.operationsSupport,
    image: jaspreet,
  },
  {
    name: 'Nate Deakers',
    role: Roles.softwareEngineer,
    image: nate,
  },
  {
    name: 'Max Metcalfe',
    role: Roles.softwareEngineer,
    image: max,
  },
  {
    name: 'Sam Nordale',
    role: Roles.softwareEngineer,
    image: sam,
  },
];

export const agents = fsmTeam.filter((person) => isAgent(person));
export const agentsWithStates = fsmTeam.filter((person) => isAgent(person) && person.states);

/*
 *  We store some agent information (like headshots / bios) in
 *  this file, but we also have some agent information in our
 *  database. agentLookup is an object we can use to source
 *  the info stored on the front end by calendly slug.
 */
export const agentLookup = agents.reduce((acc, curr) => {
  if (curr.calendly) {
    acc[curr.calendly] = curr;
  }
  return acc;
}, {});
