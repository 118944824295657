import {
  duplicateLeadCheck,
  getAgentDrxLink,
  getApplicationData,
  getExistingSurvey,
  getFrontConversations,
  getMapdQuotes,
  getMaQuotes,
  getMsQuotes,
  getPdpPlanDetails,
  getPdpQuotes,
  getScheduledEmails,
  getUserDetails,
  postEnroll,
  sendQuotes,
  silenceAEP,
  submitSurvey,
  updateUserDetails,
} from '@lib/requests';
import { useMutation, useQuery } from '@tanstack/react-query';
import { formatUpdateLeadPayload } from '../utils';

export const useApplicationData = () => {
  return useQuery(
    ['getApplicationData'],
    async () => {
      const res = await getApplicationData();
      return res;
    },
    {
      enabled: true,
      initialData: {},
      refetchOnWindowFocus: false,
    }
  );
};

// Ms Quotes
export const useMsQuotes = ({ userId, agentId, householdDiscount, oegi, dataSource }) => {
  return useQuery({
    queryKey: ['getMsQuotes', householdDiscount, oegi],
    queryFn: async () => {
      const res = await getMsQuotes({
        userId,
        agentId,
        householdDiscount,
        oegi,
        dataSource,
      });
      return res.data.plans;
    },
    initialData: () => [],
    enabled: false,
    retry: 1,
  });
};

// Pdp Quotes
export const usePdpQuotes = ({ userId, agentId }) => {
  return useQuery<any>({
    queryKey: ['getPdpQuotes'],
    queryFn: async () => {
      const res = await getPdpQuotes({
        userId,
        agentId,
      });
      return { quotes: res?.data?.quotes, currentPlan: res?.data?.currentPlan };
    },
    initialData: () => {},
    enabled: false,
    retry: 1,
  });
};

// Mapd Quotes
export const useMapdQuotes = ({ userId, agentId }) => {
  return useQuery<any>({
    queryKey: ['getMapdQuotes'],
    queryFn: async () => {
      const res = await getMapdQuotes({
        userId,
        agentId,
      });
      return { quotes: res?.data?.quotes, currentPlan: res?.data?.currentPlan };
    },
    initialData: () => {},
    enabled: false,
    retry: 1,
  });
};

// Ma Quotes
export const useMaQuotes = ({ userId, agentId }) => {
  return useQuery<any>({
    queryKey: ['getMaQuotes'],
    queryFn: async () => {
      const res = await getMaQuotes({
        userId,
        agentId,
      });
      return { quotes: res?.data?.quotes, currentPlan: res?.data?.currentPlan };
    },
    initialData: () => {},
    enabled: false,
    retry: 1,
  });
};

export const useSendQuotes = ({ userId, agentId, planType, plans }) => {
  return useMutation({
    mutationKey: ['sendQuotes'],
    mutationFn: async () => {
      const res = await sendQuotes({
        userId,
        agentId,
        planType,
        plans,
      });
      return res.data.plans;
    },
  });
};

export const usePdpPlanDetails = ({ sessionId, connecturePlanId }) =>
  useQuery({
    queryKey: ['pdpPlanDetails', connecturePlanId],
    queryFn: async () => {
      const res = await getPdpPlanDetails({ sessionId, connecturePlanId });
      return res.data;
    },
    staleTime: 10 * 60 * 1000, // 10 minutes
    refetchOnWindowFocus: false,
  });

export const usePostEnroll = () => {
  return useMutation({
    mutationKey: ['postEnroll'],
    mutationFn: postEnroll,
  });
};

export const useUserDetails = ({ userId }) => {
  return useQuery({
    queryKey: ['userDetails', userId],
    queryFn: async () => {
      const res = await getUserDetails({ userId });
      return res;
    },
    enabled: !!userId,
    refetchOnWindowFocus: false,
  });
};

export const useUpdateUser = ({ userId, values, changeSet, onSuccess }) =>
  useMutation({
    mutationKey: ['updateUser'],
    mutationFn: async () => {
      await updateUserDetails(formatUpdateLeadPayload({ userId, values, changeSet }));
    },
    onSuccess,
  });

export const useGetAgentDrxLink = ({ clientId, agentId, zipCode, planIds }) => {
  return useMutation({
    mutationKey: ['getAgentDrxLink'],
    mutationFn: async () =>
      getAgentDrxLink({
        clientId,
        agentId,
        zipCode,
        planIds,
      }),
  });
};

export const useGetScheduledEmails = ({ agentId, userId }) => {
  return useQuery(
    ['getScheduledEmails'],
    async () => {
      const res = await getScheduledEmails({ agentId, userId });
      return res;
    },
    {
      enabled: false,
      initialData: [],
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetFrontConversations = ({ agentId, userId }) => {
  return useQuery(
    ['getFrontConversations'],
    async () => {
      const res = await getFrontConversations({ agentId, userId });
      return res;
    },
    {
      enabled: false,
      initialData: [],
      refetchOnWindowFocus: false,
    }
  );
};

export const useSilenceAEP = ({ userId, aepSilenced }) => {
  return useMutation({
    mutationKey: ['silenceAEP'],
    mutationFn: async () =>
      silenceAEP({
        userId,
        silenceAEP: aepSilenced,
      }),
  });
};

export const useDuplicateLeadCheck = ({ email, phoneNumber }) => {
  return useQuery({
    queryKey: ['duplicateLeadCheck', email, phoneNumber],
    queryFn: async () => {
      const res = await duplicateLeadCheck({
        email,
        phoneNumber,
      });
      return res;
    },
    enabled: !!email || !!phoneNumber,
    refetchOnWindowFocus: false,
  });
};

export const useSubmitSurvey = ({ userId, userComment, response }) =>
  useMutation({
    mutationKey: ['submitSurvey'],
    mutationFn: async () =>
      submitSurvey({
        userId,
        userComment,
        response,
      }),
  });

export const useGetExistingSurvey = ({ userId }) =>
  useQuery(
    ['getExistingSurvey'],
    async () => {
      const res = await getExistingSurvey({ userId });
      return res;
    },
    {
      enabled: !!userId,
      initialData: [],
      refetchOnWindowFocus: false,
    }
  );
